import { GatsbySeo } from 'gatsby-plugin-next-seo'

import { RenderClient } from 'src/components/RenderClient'

function Page() {
  return (
    <RenderClient>
      <GatsbySeo />

      <div style={{ position: "relative", paddingTop: "max(60%,326px)", height: 0, width:"100%" }}>
        <iframe allow="clipboard-write"
                sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
                allowFullScreen
                style={{ position: "absolute", border: "none", width: "100%", height:"100%", left: 0, right: 0, top: 0, bottom:0 }}
                src="https://e.issuu.com/embed.html?d=arcaplanet_regali_per_tutte_le_tasche_v1&hideIssuuLogo=true&u=arcaplanet">
        </iframe>
      </div>

    </RenderClient>
  )
}

export default Page
